import { template as template_e38c2281e6fd411b9573beafc2a56772 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e38c2281e6fd411b9573beafc2a56772(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
