import { template as template_65e3032cfb9142588072fc1dfb9f2a75 } from "@ember/template-compiler";
const WelcomeHeader = template_65e3032cfb9142588072fc1dfb9f2a75(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
