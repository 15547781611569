import { template as template_a6d6437aaa314ed4a4df5ab96c602000 } from "@ember/template-compiler";
const FKLabel = template_a6d6437aaa314ed4a4df5ab96c602000(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
