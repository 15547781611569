import { template as template_bd23af517a5c464bad38f7f9d6dd9d7d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bd23af517a5c464bad38f7f9d6dd9d7d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
