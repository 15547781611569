import { template as template_a6c0ce4f332d441b806e2dc0bf2548cc } from "@ember/template-compiler";
const FKControlMenuContainer = template_a6c0ce4f332d441b806e2dc0bf2548cc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
